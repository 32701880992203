<template>
  <div>
    <div class="glo-nav">实时信息</div>
    <div class="info-data">
      <van-row type="flex" align="center">
        <van-col span="12">
          <div>
            <router-link to="/bridge/niufo/sensor">
              <div class="block-top block-pub">
                <div class="title">
                  <div>
                    <van-icon name="cluster-o" />
                    <div>传感器</div>
                  </div>
                </div>
                <div class="number">{{bhmsCount.total || 0}}</div>
              </div>
            </router-link>
          </div>
        </van-col>
        <van-col span="12">
          <div>
              <div class="block-btm block-pub clo1 glo-relative">
                <div class="title">
                    <van-icon name="certificate" />
                    <div>正常</div>
                </div>
                <div class="number">{{bhmsCount.online || 0}}</div>
              </div>
          </div>
          <van-row>
            <div>
              <div class="block-btm block-pub clo3 glo-relative">
                <div class="title">
                    <van-icon name="warning-o" />
                    <div>告警</div>
                </div>
                <div class="number">{{bhmsCount.alarm || 0}}</div>
              </div>
            </div>
          </van-row>
        </van-col>
      </van-row>
    </div>
    <div class="monitor-bridge glo-relative">
      <img :src="pointBgImg">
      <div v-for="(item,index) in moudleData" :key="index" 
        :class="item.status=='alarm' ? 'radar-result-danger' : item.status=='online' ? 'radar-result-success' : 'radar-result-offline'" 
        :style="{position: 'absolute',top:item.top,left:item.left}" @click="hintInfo(item)"
      ></div>
    </div>
    <div :class="'table glo-content ' +(noticeShow ? 'glo-magbot' : 'glo-magbot0')">
        <van-row class="tb-head">
          <van-col span="6">监测区域</van-col>
          <van-col span="6">倾角(°)</van-col>
          <van-col span="6">挠度(mm)</van-col>
          <van-col span="6">状态</van-col>
        </van-row>
        <div v-if="loading">
            <van-loading type="spinner" vertical>加载中</van-loading>
        </div>
        <div v-else>
            <div v-if="moudleData.length == 0">
                <van-divider>暂无记录</van-divider>
            </div>
            <div v-else>
              <van-row class="tb-body" v-for="(item,index) in moudleData" :key="index">
                <van-col span="6">{{item.devName || '暂无名称'}}</van-col>
                <van-col span="6">{{item.xaxisangle}}</van-col>
                <van-col span="6">{{(item.vibamp / 1).toFixed(2)}}</van-col>
                <van-col span="6">
                  <van-tag type="danger" v-if="item.status == 'alarm'">告警</van-tag>
                  <van-tag type="warning" v-else-if="item.status == 'offline'">离线</van-tag>
                  <van-tag type="success" v-else>正常</van-tag>
                </van-col>
              </van-row>
            </div>
        </div>
    </div>
    <tabbar />
  </div>
</template>
<script>
import tabbar from '@/components/bridge/NFtabbar.vue'
export default {
  components: {
    tabbar
  },
  props: ['magbot'],
  data() {
    return {
      bridgeCode:'',
      moudleData:[],
      weather:{
        icon:'104'
      },
      loading:true,
      magbotVal:'',
      bhmsCount:{
        online:'',
        total:'',
        alarm:''
      },
      pointBgImg:null
    }
  },
  watch: {
      magbot: function (val) {   
        this.magbotVal = val;
      }
  },
  methods:{
    // 获取实时监控列表
    loadList(){
      this.$api.NEWBRIDGE.sensorDetail({
        projectCode:this.projectCode
      }).then(d=>{
        if(Array.isArray(d)){
            this.moudleData = d.map( v => ({
              devName:v.devName,
              devCode:v.devCode,
              xaxisangle: v.cycleData && v.cycleData.curAnglez || 0,
              vibamp: v.cycleData && v.cycleData.cycleVibrateDataData && v.cycleData.cycleVibrateDataData.vibAmp || 0,
              top: (v.info && v.info.y || 0) +"%",
              left:(v.info && v.info.x || 0) +"%",
              status: v.devStatus
            }))
            
            this.loading = false;
        }
      })
    },
    // 获取网关和传感器数量
    devCountInfo(){
      this.$api.NEWBRIDGE.bhmsCount({
        projectCode:this.projectCode
      }).then(d => {
        this.bhmsCount = { ...d };
      })
    },
    // 监测桥梁点击显示实时信息
    hintInfo(item){
      if(item.status=='online'){
        this.$notify({
          type:'success',
          message:item.devName+': 倾角为'+(item.xaxisangle || 0)+'°，挠度为'+((item.vibamp || 0) / 1).toFixed(2)+'mm'
        })
      }else if(item.status == 'alarm'){
       this.$notify({
          message:item.devName+': 倾角为'+(item.xaxisangle || 0)+'°，挠度为'+((item.vibamp || 0) / 1).toFixed(2)+'mm'
        })
      }else if(item.status == 'offline'){
        this.$notify({
          message: item.devName+': 倾角为'+(item.xaxisangle || 0)+'°，挠度为'+((item.vibamp || 0) / 1).toFixed(2)+'mm',
          color: '#ffffff',
          background: '#6F6F6F',
        })
      }
    },
    // 点位图
    getPointImg(){
      this.$api.NEWBRIDGE.getinodeBitmap({
        projectCode:this.projectCode
      }).then(d=>{
        if(d&&d.length>0){
          this.pointBgImg = d[0].wxPath;
        }
      })
    }
  },
  computed:{
    noticeShow(){
      return window.sessionStorage.getItem('magbot') == '1' && this.magbot != 1 ? true : false
    }
  },
  mounted(){
      this.projectCode = window.sessionStorage.getItem('projectCode');
      if(this.projectCode){
        this.getPointImg();
        this.devCountInfo();
        // this.moduleList();
        this.loadList();
      }
  }
}
</script>
<style lang="scss" scoped>
.monitor-bridge{
  padding: 0 4%;
  img{
    width: 100%;
    height: 200px;
    background-color: #cccccc;
  }
  .radar-result-success {
    background: radial-gradient(rgba(32, 255, 77, 0.3) 0%, rgba(32, 255, 77, 0) 75%), repeating-radial-gradient(rgba(32, 255, 77, 0) 5.8%, rgba(32, 255, 77, 0) 18%, #20ff4d 24.6%, rgba(32, 255, 77, 0) 18.9%), linear-gradient(90deg, rgba(32, 255, 77, 0) 49.5%, #20ff4d 48%, #0dff3f 53%, rgba(32, 255, 77, 0) 50.2%), linear-gradient(0deg, rgba(32, 255, 77, 0) 49.5%, #20ff4d 49%, #20ff4d 52%, rgba(32, 255, 77, 0) 50.2%);
    width: 1.5rem;
    height: 1.5rem;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid #20ff4d;
    overflow: hidden;
  }
  .radar-result-danger {
    background: radial-gradient(rgba(255, 33, 33, 0.3) 0%, rgba(32, 255, 77, 0) 75%), repeating-radial-gradient(rgba(32, 255, 77, 0) 5.8%, rgba(32, 255, 77, 0) 18%, #df2929 24.6%, rgba(32, 255, 77, 0) 18.9%), linear-gradient(90deg, rgba(32, 255, 77, 0) 49.5%, #df2929 48%, #df2929 53%, rgba(32, 255, 77, 0) 50.2%), linear-gradient(0deg, rgba(32, 255, 77, 0) 49.5%, #df2929 49%, #df2929 52%, rgba(32, 255, 77, 0) 50.2%);
    width: 1.5rem;
    height: 1.5rem;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid #df2929;
    overflow: hidden;
  }
  .radar-result-offline {
    background: radial-gradient(rgba(255, 255, 255, 0.3) 0%, rgba(32, 255, 77, 0) 75%), repeating-radial-gradient(rgba(32, 255, 77, 0) 5.8%, rgba(32, 255, 77, 0) 18%, #cccccc 24.6%, rgba(32, 255, 77, 0) 18.9%), linear-gradient(90deg, rgba(32, 255, 77, 0) 49.5%, #cccccc 48%, #cccccc 53%, rgba(32, 255, 77, 0) 50.2%), linear-gradient(0deg, rgba(32, 255, 77, 0) 49.5%, #cccccc 49%, #cccccc 52%, rgba(32, 255, 77, 0) 50.2%);
    width: 1.5rem;
    height: 1.5rem;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid #cccccc;
    overflow: hidden;
  }
  .radar-result-success:after{
    content: ' ';
    display: block;
    background-image: linear-gradient(44deg, rgba(0, 255, 51, 0) 50%, #00ff33 100%);
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: radar-beam 5s infinite;
    animation-timing-function: linear;
    transform-origin: bottom right;
    border-radius: 100% 0 0 0;
  }
  
  .radar-result-danger:after{
    content: ' ';
    display: block;
    background-image: linear-gradient(44deg, rgba(0, 255, 51, 0) 50%, #df2929 100%);
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: radar-beam 5s infinite;
    animation-timing-function: linear;
    transform-origin: bottom right;
    border-radius: 100% 0 0 0;
  }
  .radar-result-offline:after{
    content: ' ';
    display: block;
    background-image: linear-gradient(44deg, rgba(0, 255, 51, 0) 50%, #cccccc 100%);
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: radar-beam 5s infinite;
    animation-timing-function: linear;
    transform-origin: bottom right;
    border-radius: 100% 0 0 0;
  }
  @keyframes radar-beam {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes blips {
    14% {
      background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
    }
    14.0002% {
      background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
    }
    25% {
      background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 56% 86%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
    }
    26% {
      background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 56% 86%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
      opacity: 1;
    }
    100% {
      background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 56% 86%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
      opacity: 0;
    }
  }
}
.info-data{
  padding:4%;
  .block-pub{
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 12px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px #ccc;
  }
  .block-top{
    background-color: #3296ec;
    height: 8rem;
    margin-right: 20px;
    .van-icon{font-size: 2rem;}
    .title{font-size: .75rem;display: flex;align-items: center;}
    .number{font-size: 3rem;padding-left: 4%;display: flex;align-items: center;}
  }
  .block-btm{
      .van-icon{font-size: 1.5rem;}
      .title{font-size: .55rem;}
      .number{font-size: 2rem;padding-left: 4%;}
  }
  .margin-space{margin: 6% 0 2% 0;}
  .clo1{background-color: #6acc68;margin-bottom: 10%;}
  .clo3{background-color: #e85c45}
}
.table{
  .tb-head{
    font-weight: bold;font-size: .95rem;
    background-color: #86d9e8;
    color: #ffffff;
    div{
      line-height: 2;
      padding: 8px 0;
    }
  }
  .tb-body{
    font-size: .85rem;
    border-bottom: 1px solid #fff;
    background-color: #c6f5e8;
    color: #464646;
    div{
      line-height: 2;
      padding: 8px 0;
    }
  }
}
.glo-relative{
  .link-relative{
    position: absolute;
    top: 38%;
    right: 4%;
    .van-icon{
      font-size: .8rem !important;
    }
  }
}
</style>